import React, { Fragment } from "react";
import { Card } from "react-bootstrap";

const SalesChart = () => {
  // const employeesService = new EmployeesService()

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Body>
        </Card.Body >
      </Card>
    </Fragment>
  );
};

export default SalesChart;