import { useState } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
// import EmployeesService from "../../../../../services/EmployeesService";
import { Translate } from "../../../../Enums/Tranlate";
import { Link } from "react-router-dom";
import DeleteModal from "../../../../common/DeleteModal";
import ChangeStatusModal from "../ChangeStatusModal";

const CardItem = ({item, setItem, index, setResignationModal, setAddModal, setShouldUpdate}) =>{
    const [deleteModal, setDeleteModal] = useState(false)
    const [type, setType] = useState('')
    const [status, setStatus] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    // const employeesService = new EmployeesService()

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>
                {item?.client_name}
            </td>
            <td>{item?.client_phone}</td>
            <td>{item?.company_name}</td>
            <td>{item?.company_phone}</td>
            <td>{item?.company_email}</td>
            <td>{item?.address}</td>
            <td>{item?.lead_channel}</td>
            <td>{item?.responsible_person}</td>
            <td>{item?.reference}</td>
            <td>{item?.deal_value}</td>
            <td>{item?.expected_close_date?.split('T')[0]}</td>
            <td>{item?.lead_month}</td>
            <td>{item?.lead_year}</td>
            <td>
                <Badge className="cursor-pointer" onClick={()=> setStatus(true)} variant={item?.status === "On Progress on Hold" ? 'primary' : item?.status === 'Completed' ? 'success' : 'secondary'}>
                    {item?.status}
                </Badge>
            </td>
            <td>
                {(isExist("edit_crm") && isExist("delete_crm")) && <Dropdown>
                    <Dropdown.Toggle className="light sharp i-false">
                        <i className="la la-ellipsis-h" style={{fontSize: '27px'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {isExist("edit_crm") && <Dropdown.Item onClick={()=> {
                            setItem(item)
                            setAddModal(true)
                        }}> {Translate[lang]?.edit}</Dropdown.Item>}
                        {isExist("delete_crm") && <Dropdown.Item onClick={()=> {
                            setDeleteModal(true)
                            setType('delete')
                        }}>{Translate[lang]?.delete}</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>}
            </td>
            {deleteModal && <DeleteModal
                      open={deleteModal}
                      titleMsg={item?.client_name}
                      deletedItem={item}
                    //   modelService={employeesService}
                      onCloseModal={setDeleteModal}
                      setShouldUpdate={setShouldUpdate}
                      type={type}
                    />}
            {status && <ChangeStatusModal
                addModal={status} 
                setAddModal={()=> setStatus(false)} 
                item={item} 
                setShouldUpdate={setShouldUpdate}
            />}
            </tr>
    )
}
export default CardItem;