import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OrganizationService from "../../../../services/OrganizationService";
import {useLocation} from 'react-router-dom';
import {AvField, AvForm} from "availity-reactstrap-validation";
import { useSelector } from "react-redux";
import uploadImg from '../../../../images/upload-img.png';
import { Translate } from "../../../Enums/Tranlate";
import BaseService from "../../../../services/BaseService";
import Loader from "../../../common/Loader";
import '../style.scss'

const AddOrganization = () => {
   const location = useLocation();
   const [formData, setFormData] = useState({
      name: '',
      organization_attachments: [
         {
           attach: "",
           attach_name: "",
           loading: false
         }
       ]
   })
   const [loading, setLoading] = useState(false)
   const [id, setId] = useState(null)
   const [view, setView] = useState(false)
   const navigate = useNavigate()
   const organizationService = new OrganizationService()
   const lang = useSelector(state=> state.auth?.lang)

   useEffect(()=>{
      if(location?.pathname.includes('edit') || location?.pathname.includes('view')){
         let item = location.state
         setId(item?.id)
         setFormData({
            name: item?.name,
            organization_attachments: item?.organization_attachments?.map(res=>{
               return {
                  ...res,
                  loading: false
               }
            })
         })
      }
      if(location?.pathname.includes('view')){
         setView(true)
      }
   },[location])

   const inputHandler = (e) =>{
      setFormData({...formData, [e.target.name]: e.target.value})
   }

   const fileHandler = (e, index) => {
      let files = e.target.files
      const filesData = Object.values(files)
      if (filesData?.length) {
         let update = formData?.organization_attachments?.map((res, ind)=> {
            if(ind === index){
               return {
                  ...res,
                  loading: true
               }
            } else {
               return res
            }
         })
         setFormData({...formData, organization_attachments: update})

         new BaseService().postUpload(filesData[0]).then(res=>{
            if(res?.status === 200){
               let update = formData?.organization_attachments?.map((atta, ind)=> {
                  if(ind === index){
                     return {
                        ...atta,
                        attach: res?.data?.url,
                        loading: false
                     }
                  } else {
                     return atta
                  }
               })
               setFormData({...formData, organization_attachments: update})
            }
         })
      }
   }

   const onSubmit = (e) =>{
      e.preventDefault();
      
      let data = {
         name: formData?.name,
         organization_attachments: formData?.organization_attachments?.map(res=>{
            return{
               attach: res?.attach,
               attach_name: res?.attach_name
            }
         })
      }

      if(!!id){
         setLoading(true)
         organizationService.update(id, data).then((response) =>{
            if(response?.status === 200){
               toast.success(Translate[lang].admin_updated_successfully)
               navigate('/organization')
            }
            setLoading(false)
         })
      } else {
         setLoading(true)
         organizationService.create(data).then((response) =>{
            if(response?.status === 201){
               toast.success(`${Translate[lang].added} ${Translate[lang].admin} ${Translate[lang].successfully}`)
               navigate('/organization')
            }
            setLoading(false)
         })
      }
   }

   return (
      <Card className="p-4 add-organization">
         <AvForm onValidSubmit={onSubmit}>
         <div className="row">
            <div className="col-lg-6 col-sm-6 mb-3">
               <AvField
						label ={`${Translate[lang]?.name}`}
						name ='name'
						type='text'
						value={formData?.name}
                  disabled={view}
						validate={{
							required: {
								value:true,
								errorMessage: Translate[lang].field_required
							},
						}}
						placeholder={`${Translate[lang]?.name}`}
						onChange={(e)=> inputHandler(e)}
					/>
            </div>
            <Col md={6}></Col>
            <Col md={12}>
               <label>{Translate[lang].organization_attachments}</label>
            </Col>

            {formData?.organization_attachments?.map((attatchment, index) => {
                     return (
                        <>
                           <Col md={6} className="mb-3 d-flex align-items-center">
                              <div className="w-100">
                                 <AvField
                                    label ={`${index+1}) ${view ? '' : Translate[lang]?.name}`}
                                    name ={`name${index}`}
                                    type='text'
                                    className='w-100'
                                    disabled={view}
                                    value={attatchment?.attach_name}
                                    validate={{
                                       required: {
                                          value:true,
                                          errorMessage: Translate[lang].field_required
                                       },
                                    }}
                                    placeholder={`${Translate[lang]?.name}`}
                                    onChange={(e)=> {
                                       let update = formData?.organization_attachments?.map((res, ind)=> {
                                          if(index === ind){
                                             return {
                                                ...res,
                                                attach_name: e.target?.value
                                             }
                                          } else {
                                             return res
                                          }
                                       })
                                       setFormData({...formData, organization_attachments: update})
                                    }}
                                 />
                              </div>
                           </Col>
                           {!view && <Col md={3} className='mb-3'>
                            <div className='form-group w-100'>
                                <div className="image-placeholder">	
                                    <div className="avatar-edit">
                                          <input 
                                             type="file" 
                                             name='attach' 
                                             onChange={(e) => fileHandler(e, index)} 
                                             value={''}
                                             id={`imageUpload${index}`} 
                                          /> 					
                                          <label htmlFor={`imageUpload${index}`}></label>
                                    </div>
                                    <div className="avatar-preview2 m-auto">
                                        <div id={`imagePreview${index}`}>
                                          {(!attatchment?.loading && !attatchment?.attach) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                className="upload-img"
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                          />}
                                          {!!attatchment?.attach && <div className="h-100">
                                             {!view && <i 
                                                className="la la-trash text-danger"
                                                style={{
                                                   zIndex:'2',
                                                   position: 'absolute',
                                                   border: '1px solid #ff4847',
                                                   borderRadius: '50%',
                                                   top: '0px',
                                                   left: '0px',
                                                   padding: '4px',
                                                   fontSize: '20px',
                                                   background: '#fff',
                                                   cursor: 'pointer'
                                                }}
                                                onClick={()=>{
                                                   let update = formData.organization_attachments?.map((res,ind)=>{
                                                      if(ind === index){
                                                         return {
                                                            ...res,
                                                            attach: ''
                                                         }
                                                      } else {
                                                         return res
                                                      }
                                                   })
                                                   setFormData({...formData, organization_attachments: update})
                                                }}
                                             ></i>}
                                             <a href={attatchment?.attach} target='_blank' rel="noreferrer">
                                                {attatchment?.attach.includes('pdf') ?
                                                <i className="la la-file-pdf" 
                                                   style={{
                                                      fontSize: '6rem',
                                                      textAlign: 'center',
                                                      height: '100%',
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "space-around"
                                                   }}></i>
                                                : <img src={attatchment?.attach} alt={index} className='w-100 h-100' />}
                                             </a>
                                          </div>}
                                         {attatchment?.loading && <Loader />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                           </Col>}
                           {view && <Col md={3} className="mb-3">
                              {!!attatchment?.attach && <div className="image-preview">
                                 <a href={attatchment?.attach} target='_blank' rel="noreferrer">
                                    {attatchment?.attach.includes('pdf') ?
                                    <i className="la la-file-pdf d-block" style={{fontSize: '6rem'}}></i>
                                    : <img src={attatchment?.attach} alt={index} className='w-100' />}
                                 </a>
                              </div>}
                           </Col>}
                        </>
                     );
            })}
         </div>
         {!view && <div className="d-flex mt-5">
            <Button variant="outline-primary" type="button" onClick={()=>{
               setFormData({...formData, organization_attachments: [...formData?.organization_attachments, {
                  attach: "",
                  attach_name: "",
                  loading: false
               }]})
            }}>{Translate[lang]?.add_new_value}</Button>
         </div>}
         <div className="d-flex justify-content-between mt-5">
            <Button variant="secondary" type="button" onClick={()=> navigate('/organization')}>{Translate[lang]?.cancel}</Button>
            {!view && <Button variant="primary" type="submit" disabled={loading}>{Translate[lang]?.submit}</Button>}
         </div>
      </AvForm>
      </Card>
   );
};

export default AddOrganization;
