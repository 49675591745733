import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";
import Select from 'react-select';

const AddSalesModal = ({addModal, setAddModal, item, setShouldUpdate})=>{
    const [formData, setFormData] = useState({
        name: '',
        salary: '',
        commission: "",
    })
    const [isAdd, setIsAdd] = useState(true)
    const [loading, setLoading] = useState(false)
    const [commissionsOptions, setcommissionsOptions] = useState([])
    const lang = useSelector(state=> state.auth.lang)

    useEffect(()=>{
        setcommissionsOptions([
            {label: Translate[lang].percentage, value: "percentage"},
            {label: Translate[lang].fixed, value: "fixed"}
        ])
    } ,[lang])

    useEffect(() => {
        if(Object.keys(item)?.length === 0){
            setIsAdd(true)
        } else {
            setIsAdd(false)

            setFormData({
                id: item.id,
                name: item.name,
                civil_id: item.civil_id,
                expiry_date: item.expiry_date?.split('T')[0],
                passport_no: item.passport_no,
                passport_expiry_date: item.passport_expiry_date?.split('T')[0],
                job_title: item.job_title,
                start_date: item.start_date?.split('T')[0],
                salary: item.salary,
                personal_email: item.personal_email,
                company_email: item.company_email,
                iban: item.iban,
            })
        }
    },[item])

    const submit = (e) =>{
        if(!formData?.nationality){
            return
        }
        e.preventDefault();
        let data ={ 
            ...formData,
            jop_type: formData?.jop_type?.value,
            is_full_time: formData?.jop_type?.value === 'full time',
            nationality: formData?.nationality?.value,
            assets_id: formData.assets?.map(res=> res.value),
            department_id: formData.department.id
        }
        delete data.department
        delete data.assets

        if(isAdd){
            // employeesService.create(data)?.then(res=>{
            //     if(res && res?.status === 201){
            //         toast.success('Employee Added Successfully')
            //         setShouldUpdate(prev=> !prev)
            //         setAddModal()
            //     }
            // })
        } else {
            delete data.id

            // employeesService.update(formData?.id, data)?.then(res=>{
            //     if(res && res?.status === 200){
            //         toast.success('Employee Updated Successfully')
            //         setShouldUpdate(prev=> !prev)
            //         setAddModal()
            //     }
            // })
        }
    }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
                <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{isAdd ? Translate[lang]?.add : Translate[lang]?.edit} {Translate[lang]?.sales}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.name}
                                type='text'
                                placeholder={Translate[lang]?.name}
                                bsSize="lg"
                                name='name'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.name}
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <label className="text-label">{Translate[lang]?.commission}</label>
                            <Select
                                value={formData?.commission}
                                name="commission"
                                placeholder={Translate[lang]?.select}
                                options={commissionsOptions}
                                onChange={(e)=> setFormData({...formData, commission: e})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.salary}
                                type='number'
                                placeholder={formData?.commission?.value === "percentage" ? "1% e.g" : "1 e.g" }
                                bsSize="lg"
                                min={0}
                                max={formData?.commission?.value === "percentage" ? '100' : ''}
                                name='salary'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.salary}
                                onChange={(e) => setFormData({...formData, salary: e.target.value})}
                            />
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setAddModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{isAdd ? Translate[lang]?.add : Translate[lang]?.edit}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default AddSalesModal;