import React, { Fragment } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Translate } from "../../Enums/Tranlate";
import Leads from "./Lead";
import Sales from "./Sales";
import Projects from "./Projects";
import SalesChart from "./SalesChart";
import BulkEmail from "./BulkEmail";

const tabs = ['sales_chart', 'leads', 'sales', 'projects', 'bulk_email']

const CRM = () => {
    const [selectTab, setSelectTab] = useState('sales_chart')
    const lang = useSelector(state=> state.auth?.lang)

  return (
    <Fragment>
      <div className="mb-3">
      <div className="tabs-div">
            {tabs?.map((tab,index)=>{
              return <p
                key={index}
                className={`${tab === selectTab ? "activeP" : "" }`}
                onClick={() => setSelectTab(tab)}
                >
                    <span></span> {Translate[lang][tab]}
                </p>
            })}
          </div>
      </div>

      {selectTab === 'sales_chart' && <SalesChart />}
      {selectTab === 'leads' && <Leads />}
      {selectTab === 'sales' && <Sales />}
      {selectTab === 'projects' && <Projects />}
      {selectTab === 'bulk_email' && <BulkEmail />}
    </Fragment>
  );
};

export default CRM;