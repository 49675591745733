import React, { useEffect } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import { Translate } from "../../Enums/Tranlate";
import CardItem from "./CardItem";
import './style.scss'
import EmployeesService from "../../../services/EmployeesService";

const Notification = () => {
    const [data, setData] = useState([])
    const [hasData, setHasData] = useState(null)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const employeesService = new EmployeesService()

    useEffect(()=>{
      setLoading(true)
      employeesService.notification().then(res=>{
        if(res?.status){
          setData([...res.data?.data]);
          if (res.data?.data?.length > 0) {
            setHasData(1);
          } else {
            setHasData(0);
          }
        }
        setLoading(false)
      }).catch(()=> setLoading(false))
    },[])

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body className={`${hasData === 0 && 'text-center'} `}>
            {loading && <div style={{height: '300px'}}>
                <Loader />
              </div>}
              {(hasData === 1 && !loading) && <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.name}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.job_title}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.department}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.civil_id}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.civil_id_status}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.passport_status}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.residency_status}</strong>
                    </th>
                    {/* <th></th>
                    <th></th> */}
                  </tr>
                </thead>

                <tbody className="table-body">
                    {data?.map((item, index) =>{
                        return <CardItem 
                            index= {index}
                            key= {index}
                            item={item}
                        />
                    })}
                </tbody>
              </Table>}
              {hasData === 0 && <NoData />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Notification;