import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";
import uploadImg from '../../../../../images/upload-img.png';
import Select from 'react-select';
import EmployeesService from "../../../../../services/EmployeesService";
import Loader from "../../../../common/Loader";
import BaseService from "../../../../../services/BaseService";

const AddProjectsModal = ({addModal, setAddModal, item, setShouldUpdate})=>{
    const [formData, setFormData] = useState({
        project_name: "",
        project_type: "",
        client_name: '',
        client_phone: '',
        client_email: '',
        project_date: "",
        no_of_installments: "",
        project_pay: "",
        employee_reference: "",
        project_stage: "",
        attachments: []
    })
    const [isAdd, setIsAdd] = useState(true)
    const [loading, setLoading] = useState(false)
    const [channelsOptions, setChannelsOptions] = useState([])
    const [employeesOptions, setEmployeesOptions] = useState([])
    const lang = useSelector(state=> state.auth.lang)
    const projectStageOptions = [
        {label: "New", value: "New"},
        {label: "On Progress on Hold", value: "On Progress on Hold"},
        {label: "Completed", value: "Completed"},
    ]
    const projectOptions = [
        {label: "Saas", value: 'Saas'},
        {label: "Pass", value: 'Pass'},
        {label: "Hosting", value: 'Hosting'},
        {label: "Maintain", value: 'Maintain'},
    ]    
    const projectPayOptions = [
        {label: "Full", value: 'Full'},
        {label: "In advance ", value: 'In advance '},
        {label: "Installments", value: 'Installments'},
        {label: "On receive ", value: 'On receive '},
    ]

    useEffect(()=>{
        new EmployeesService().getList().then(res=> {
            if(res?.status){
                const response = res?.data?.data?.data?.map(emp => {
                    return {
                        label: emp?.name,
                        value: emp?.id
                    }
                })
                setEmployeesOptions(response)
            }
        })
    }, [])

    useEffect(() => {
        if(Object.keys(item)?.length === 0){
            setIsAdd(true)
        } else {
            setIsAdd(false)

            setFormData({
                id: item.id,
                name: item.name,
                civil_id: item.civil_id,
                expiry_date: item.expiry_date?.split('T')[0],
                passport_no: item.passport_no,
                passport_expiry_date: item.passport_expiry_date?.split('T')[0],
                job_title: item.job_title,
                start_date: item.start_date?.split('T')[0],
                salary: item.salary,
                personal_email: item.personal_email,
                company_email: item.company_email,
                iban: item.iban,
            })
        }
    },[item])

    const submit = (e) =>{
        if(!formData?.nationality){
            return
        }
        e.preventDefault();
        let data ={ 
            ...formData,
            jop_type: formData?.jop_type?.value,
            is_full_time: formData?.jop_type?.value === 'full time',
            nationality: formData?.nationality?.value,
            assets_id: formData.assets?.map(res=> res.value),
            department_id: formData.department.id
        }
        delete data.department
        delete data.assets

        if(isAdd){
            // employeesService.create(data)?.then(res=>{
            //     if(res && res?.status === 201){
            //         toast.success('Employee Added Successfully')
            //         setShouldUpdate(prev=> !prev)
            //         setAddModal()
            //     }
            // })
        } else {
            delete data.id

            // employeesService.update(formData?.id, data)?.then(res=>{
            //     if(res && res?.status === 200){
            //         toast.success('Employee Updated Successfully')
            //         setShouldUpdate(prev=> !prev)
            //         setAddModal()
            //     }
            // })
        }
    }

    const fileHandler = (e) => {
        let files = e.target.files
        const filesData = Object.values(files)
        setLoading(true)
        if (filesData?.length) {
            new BaseService().postUpload(filesData[0]).then(res=>{
                if(res?.status === 200){
                    // setFormData({...formData, attachments: [...formData.attachments, res?.data?.url]})
                }
                setLoading(false)
            }).catch(()=> setLoading(false))
        }
    }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
                <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{isAdd ? Translate[lang]?.add : Translate[lang]?.edit} {Translate[lang]?.product}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.project_name}
                                type='text'
                                placeholder={Translate[lang]?.project_name}
                                bsSize="lg"
                                name='project_name'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.project_name}
                                onChange={(e) => setFormData({...formData, project_name: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <label className="text-label">{Translate[lang]?.project_type}</label>
                            <Select
                                value={formData?.project_type}
                                name="project_type"
                                placeholder={Translate[lang]?.select}
                                options={projectOptions}
                                onChange={(e)=> setFormData({...formData, project_type: e})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.client_name}
                                type='text'
                                placeholder={Translate[lang]?.client_name}
                                bsSize="lg"
                                name='client_name'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.client_name}
                                onChange={(e) => setFormData({...formData, client_name: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.client_phone}
                                type='number'
                                placeholder={Translate[lang]?.client_phone}
                                bsSize="lg"
                                name='client_phone'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.client_phone}
                                onChange={(e) => setFormData({...formData, client_phone: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.client_email}
                                type='email'
                                placeholder={Translate[lang]?.client_email}
                                bsSize="lg"
                                name='client_email'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.client_email}
                                onChange={(e) => setFormData({...formData, client_email: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.project_date}
                                type='date'
                                placeholder={Translate[lang]?.project_date}
                                bsSize="lg"
                                name='project_date'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.project_date}
                                onChange={(e) => setFormData({...formData, project_date: e.target.value})}
                            />
                        </Col>
                        <Col md={6}  className="mb-3">
                            <label className="text-label">{Translate[lang]?.project_pay}</label>
                            <Select
                                value={formData?.project_pay}
                                name="project_pay"
                                placeholder={Translate[lang]?.select}
                                options={projectPayOptions}
                                onChange={(e)=> setFormData({...formData, project_pay: e})}
                            />
                        </Col>
                        {formData?.project_pay?.value === "Installments" && <Col md={6}>
                            <AvField
                                label={Translate[lang]?.no_of_installments}
                                type='text'
                                placeholder={Translate[lang]?.no_of_installments}
                                bsSize="lg"
                                name='no_of_installments'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.no_of_installments}
                                onChange={(e) => setFormData({...formData, no_of_installments: e.target.value})}
                            />
                        </Col>}
                        <Col md={6}>
                            <label className="text-label">{Translate[lang]?.employee_reference}</label>
                            <Select
                                value={formData?.employee_reference}
                                name="employee_reference"
                                placeholder={Translate[lang]?.select}
                                options={employeesOptions}
                                onChange={(e)=> setFormData({...formData, employee_reference: e})}
                            />
                        </Col>
                        <Col md={6}>
                            <label className="text-label">{Translate[lang]?.project_stage}</label>
                            <Select
                                value={formData?.project_stage}
                                name="project_stage"
                                placeholder={Translate[lang]?.select}
                                options={projectStageOptions}
                                onChange={(e)=> setFormData({...formData, project_stage: e})}
                            />
                        </Col>
                        <Col md={12} className='mt-3'>
                            <div className='form-group w-100'>
                                <label className="m-0">{Translate[lang]?.attachments}</label>
                                <div className="image-placeholder">	
                                    <div className="avatar-edit">
                                        <input type="file" onChange={(e) => fileHandler(e)} id={`imageUpload1`} /> 					
                                        <label htmlFor={`imageUpload1`}  name=''></label>
                                    </div>
                                    <div className="avatar-preview2 m-auto">
                                        <div id={`imagePreview`}>
                                        {!loading && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {loading && <Loader />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {!!formData.attachments?.length && formData.attachments?.map((attachment, index)=>{
                            return <Col md={3} key={index}>
                                <div>
                                    <i 
                                        className="la la-trash text-danger"
                                        onClick={()=>{
                                            let update = formData.attachments?.filter((_,ind)=> index !== ind)
                                            setFormData({...formData, attachments: update})
                                        }}
                                    ></i>
                                    {attachment.includes('pdf') ?
                                        <a href={attachment} target='_blank' rel="noreferrer">
                                            <i className="la la-file-pdf d-block" style={{fontSize: '6rem'}}></i>
                                        </a>
                                     : <img src={attachment} alt={index} className='w-100' />
                                     }
                                    
                                </div>
                            </Col>
                        })}
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setAddModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{isAdd ? Translate[lang]?.add : Translate[lang]?.edit}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default AddProjectsModal;