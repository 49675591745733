import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";
import MonthDropDown from "../../../../Enums/MonthDropDown";
import YearDropDown from "../../../../Enums/YearDropDown";
import Select from 'react-select';

const AddLeadsModal = ({addModal, setAddModal, item, setShouldUpdate})=>{
    const [formData, setFormData] = useState({
        client_name: '',
        client_phone: '',
        company_name: "",
        company_phone: "",
        company_email: "",
        sales: "",
        address: "",
        responsible_person: "",
        reference: "",
        deal_value: "",
        expected_close_date: "",
        lead_month: "",
        lead_year: "",
    })
    const [isAdd, setIsAdd] = useState(true)
    const [loading, setLoading] = useState(false)
    const [channelsOptions, setChannelsOptions] = useState([])
    const lang = useSelector(state=> state.auth.lang)

    useEffect(()=>{
        setChannelsOptions([
            {label: Translate[lang].social_media, value: "social_media"},
            {label: Translate[lang].call_center, value: "call_center"},
            {label: Translate[lang].website, value: "website"},
            {label: Translate[lang].email, value: "email"},
            {label: Translate[lang].landing_page, value: "landing_page"},
            {label: Translate[lang].reference, value: "reference"},
            {label: Translate[lang].offline_campaign, value: "offline_campaign"},
            {label: Translate[lang].data_center_hub, value: "data_center_hub"}
        ])
    } ,[lang])

    useEffect(() => {
        if(Object.keys(item)?.length === 0){
            setIsAdd(true)
        } else {
            setIsAdd(false)

            setFormData({
                id: item.id,
                name: item.name,
                civil_id: item.civil_id,
                expiry_date: item.expiry_date?.split('T')[0],
                passport_no: item.passport_no,
                passport_expiry_date: item.passport_expiry_date?.split('T')[0],
                job_title: item.job_title,
                start_date: item.start_date?.split('T')[0],
                salary: item.salary,
                personal_email: item.personal_email,
                company_email: item.company_email,
                iban: item.iban,
            })
        }
    },[item])

    const submit = (e) =>{
        if(!formData?.nationality){
            return
        }
        e.preventDefault();
        let data ={ 
            ...formData,
            jop_type: formData?.jop_type?.value,
            is_full_time: formData?.jop_type?.value === 'full time',
            nationality: formData?.nationality?.value,
            assets_id: formData.assets?.map(res=> res.value),
            department_id: formData.department.id
        }
        delete data.department
        delete data.assets

        if(isAdd){
            // employeesService.create(data)?.then(res=>{
            //     if(res && res?.status === 201){
            //         toast.success('Employee Added Successfully')
            //         setShouldUpdate(prev=> !prev)
            //         setAddModal()
            //     }
            // })
        } else {
            delete data.id

            // employeesService.update(formData?.id, data)?.then(res=>{
            //     if(res && res?.status === 200){
            //         toast.success('Employee Updated Successfully')
            //         setShouldUpdate(prev=> !prev)
            //         setAddModal()
            //     }
            // })
        }
    }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
                <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{isAdd ? Translate[lang]?.add : Translate[lang]?.edit} {Translate[lang]?.leads}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.client_name}
                                type='text'
                                placeholder={Translate[lang]?.client_name}
                                bsSize="lg"
                                name='client_name'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.client_name}
                                onChange={(e) => setFormData({...formData, client_name: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.client_phone}
                                type='number'
                                placeholder={Translate[lang]?.client_phone}
                                bsSize="lg"
                                name='client_phone'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.client_phone}
                                onChange={(e) => setFormData({...formData, client_phone: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.company_name}
                                type='text'
                                placeholder={Translate[lang]?.company_name}
                                bsSize="lg"
                                name='company_name'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.company_name}
                                onChange={(e) => setFormData({...formData, company_name: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.company_phone}
                                type='number'
                                placeholder={Translate[lang]?.company_phone}
                                bsSize="lg"
                                name='company_phone'
                                validate={{
                                    required:{
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.company_phone}
                                onChange={(e) => setFormData({...formData, company_phone: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.company_email}
                                type='email'
                                placeholder={Translate[lang]?.company_email}
                                bsSize="lg"
                                name='company_email'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.company_email}
                                onChange={(e) => setFormData({...formData, company_email: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <label className="text-label">{Translate[lang]?.sales}</label>
                            <Select
                                value={formData?.sales}
                                name="sales"
                                placeholder={Translate[lang]?.select}
                                options={[]}
                                onChange={(e)=> setFormData({...formData, sales: e})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.responsible_person}
                                type='text'
                                placeholder={Translate[lang]?.responsible_person}
                                bsSize="lg"
                                name='responsible_person'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.responsible_person}
                                onChange={(e) => setFormData({...formData, responsible_person: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.reference}
                                type='text'
                                placeholder={Translate[lang]?.reference}
                                bsSize="lg"
                                name='reference'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.reference}
                                onChange={(e) => setFormData({...formData, reference: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.deal_value}
                                type='number'
                                placeholder={Translate[lang]?.deal_value}
                                bsSize="lg"
                                name='deal_value'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.deal_value}
                                onChange={(e) => setFormData({...formData, deal_value: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.expected_close_date}
                                type='date'
                                placeholder={Translate[lang]?.expected_close_date}
                                bsSize="lg"
                                name='expected_close_date'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.expected_close_date}
                                onChange={(e) => setFormData({...formData, expected_close_date: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <label className="text-label">{Translate[lang]?.channel}</label>
                            <Select
                                value={formData?.channel}
                                name="channel"
                                placeholder={Translate[lang]?.select}
                                options={channelsOptions}
                                onChange={(e)=> setFormData({...formData, channel: e})}
                            />
                        </Col>
                        <Col md={6}>
                            <MonthDropDown
                                params={formData?.lead_month} 
                                changeParams={(e) => setFormData({...formData, lead_month: e})} 
                            />
                        </Col>
                        <Col md={6}>
                            <YearDropDown
                                params={formData?.lead_year} 
                                changeParams={(e) => setFormData({...formData, lead_year: e})} 
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <AvField
                                label={Translate[lang]?.address}
                                type='text'
                                placeholder={Translate[lang]?.address}
                                bsSize="lg"
                                name='address'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.address}
                                onChange={(e) => setFormData({...formData, address: e.target.value})}
                            />
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setAddModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{isAdd ? Translate[lang]?.add : Translate[lang]?.edit}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default AddLeadsModal;